import 'select2'
import 'select2/dist/css/select2.css'
import html5tooltips from 'html5tooltipsjs'
import 'html5tooltipsjs/html5tooltips.css'
import ClipboardJS from 'clipboard'

/**
 * Place any jQuery/helper plugins in here.
 */
$(function () {
    /**
     * Checkbox tree for permission selecting
     */
    let permissionTree = $('.permission-tree :checkbox');

    permissionTree.on('click change', function (){
        if($(this).is(':checked')) {
            $(this).siblings('ul').find('input[type="checkbox"]').attr('checked', true).attr('disabled', true);
        } else {
            $(this).siblings('ul').find('input[type="checkbox"]').removeAttr('checked').removeAttr('disabled');
        }
    });

    permissionTree.each(function () {
        if($(this).is(':checked')) {
            $(this).siblings('ul').find('input[type="checkbox"]').attr('checked', true).attr('disabled', true);
        }
    });

    /**
     * Disable submit inputs in the given form
     *
     * @param form
     */
    function disableSubmitButtons(form) {
        form.find('input[type="submit"]').attr('disabled', true);
        form.find('button[type="submit"]').attr('disabled', true);
    }

    /**
     * Enable the submit inputs in a given form
     *
     * @param form
     */
    function enableSubmitButtons(form) {
        form.find('input[type="submit"]').removeAttr('disabled');
        form.find('button[type="submit"]').removeAttr('disabled');
    }

    /**
     * Disable all submit buttons once clicked
     */
    $('form').submit(function () {
        disableSubmitButtons($(this));
        return true;
    });

    /**
     * Add a confirmation to a delete button/form
     */
    $('body').on('submit', 'form[name=delete-item]', function(e) {
        e.preventDefault();

        Swal.fire({
            title: 'Are you sure you want to delete this item?',
            showCancelButton: true,
            confirmButtonText: 'Confirm Delete',
            cancelButtonText: 'Cancel',
            icon: 'warning'
        }).then((result) => {
            if (result.value) {
                this.submit()
            } else {
                enableSubmitButtons($(this));
            }
        });
    })
        .on('submit', 'form[name=confirm-item]', function (e) {
            e.preventDefault();

            Swal.fire({
                title: 'Are you sure you want to do this?',
                showCancelButton: true,
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel',
                icon: 'warning'
            }).then((result) => {
                if (result.value) {
                    this.submit()
                } else {
                    enableSubmitButtons($(this));
                }
            });
        })
        .on('click', 'a[name=confirm-item]', function (e) {
        /**
         * Add an 'are you sure' pop-up to any button/link
         */
        e.preventDefault();

        Swal.fire({
            title: 'Are you sure you want to do this?',
            showCancelButton: true,
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel',
            icon: 'info',
        }).then((result) => {
            result.value && window.location.assign($(this).attr('href'));
        });
    });

    // Remember tab on page load
    $('a[data-toggle="tab"], a[data-toggle="pill"]').on('shown.bs.tab', function (e) {
        let hash = $(e.target).attr('href');
        history.pushState ? history.pushState(null, null, hash) : location.hash = hash;
    });

    let hash = window.location.hash;
    if (hash && hash != '##') {
        $('.nav-link[href="'+hash+'"]').tab('show');
    }

    $('.select2').select2()

    $('.select2-document').select2({
        minimumResultsForSearch: Infinity,
        templateSelection: selectTemplate,
        templateResult: selectTemplate,
    })

    function selectTemplate(option) {
        return option.title
            ? $('<div class="md:text-lg font-semibold">' + option.text + '</div><div class="text-sm font-semibold text-cyan">+ RM ' + option.title + '</div>')
            : $('<div class="md:text-lg font-semibold text-cyan">' + option.text + '</div>')
    }

    /**
     * Auto-hide and resize navbar when scrolling
     */
    const nav = document.querySelector('.nav')
    if(nav) {
        var prevScrollpos = window.pageYOffset;
        window.addEventListener('scroll', fixNav)
    }

    function fixNav() {
        var currentScrollPos = window.pageYOffset;

        if (prevScrollpos > currentScrollPos) {
            nav.classList.add('top-0')
            nav.classList.remove('-top-[100px]')
        } else {
            nav.classList.remove('top-0')
            nav.classList.add('-top-[100px]')
        }

        prevScrollpos = currentScrollPos;

        if (window.scrollY > nav.offsetHeight + 50) {
            nav.classList.add('active','group','shadow-lg')
        } else {
            nav.classList.remove('active','group','shadow-lg')
        }
    }
});

/**
 * Accordion button listener
 * Dependency: FontAwesome (optional)
 * Source: https://tailwindcomponents.com/component/accordion-with-tailwindcss
 *
 * <div class="accordion-header"><!-- Header --><i class="fa-solid fa-plus"></i></div>
 */

document.body.addEventListener('DOMNodeInserted', () => {
    const accordionHeader = document.querySelectorAll('.accordion-header')
    accordionHeader.forEach((el) => {
        if(!el.hasAccordionHeader) {
            el.hasAccordionHeader = true
            el.addEventListener('click', (ev) => toggleAccordion(el, ev))
        }
    })
})

function toggleAccordion(el, ev) {
    // if(ev && ev.target && ev.pointerId >= 0) {
        // const header = ev.target.closest('.accordion-header')
        const header = el
        const accordionContent = header.nextElementSibling

        if(accordionContent) {
            let accordionMaxHeight = accordionContent.style.maxHeight

            if (accordionMaxHeight == "0px" || accordionMaxHeight.length == 0) {
                accordionContent.style.maxHeight = `${accordionContent.scrollHeight}px`
                setTimeout(() => {
                    accordionContent.style.maxHeight = `max-content`
                }, 500)

                if(header.querySelector("svg")) {
                    header.querySelector("svg").classList.add("rotate-[135deg]")
                }
            } else {
                accordionContent.style.maxHeight = `${accordionContent.scrollHeight}px`
                setTimeout(() => {
                    accordionContent.style.maxHeight = `0px`
                }, 10)

                if(header.querySelector("svg")) {
                    header.querySelector("svg").classList.remove("rotate-[135deg]")
                }
            }
        }
    // }
}

var clipboard = new ClipboardJS('.btn-copy')

clipboard.on('success', function(e) {
    e.trigger.setAttribute('data-tooltip', 'Copied!')
    html5tooltips.refresh()

    setTimeout(() => {
        e.trigger.setAttribute('data-tooltip', 'Copy')
        html5tooltips.refresh()
    }, 1000)
})
